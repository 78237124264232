const env = {
  SERVER: 'https://api.loyalyy.com',
  // API_URL: 'http://localhost:8082/api',
  API_URL: 'https://api.loyalyy.com/api',
  STRIPE: {
    PUBLIC_KEY: '',
    CLIENT_ID: '',
  },
  GHL: {},
}

export default env
