import React, { lazy } from 'react'
import ComponentLoader from './component-loader'

const RegisterPassFormGrothtool = ComponentLoader(
  lazy(() => import('screens/public/register-new-pass-growthtool'))
)
const RegisterPassFormCampaign = ComponentLoader(
  lazy(() => import('screens/public/register-new-pass'))
)

const Pass = ComponentLoader(lazy(() => import('screens/public/Pass')))

const PassGrowthTool = ComponentLoader(
  lazy(() => import('screens/public/Pass-growthtool'))
)

// NEWSFEED
const NewsFeed = ComponentLoader(lazy(() => import('screens/public/newsfeed')))

// AUTHORIZATION
const Authorization = ComponentLoader(
  lazy(() => import('screens/public/authorization'))
)

// INTEGRATIONS
const Associate = ComponentLoader(
  lazy(() => import('screens/admin/Integration/ghl/associate'))
)

const PublicRoutes = [
  {
    path: 'registration',
    children: [
      {
        path: 'tool/:growthtool_code',
        element: <RegisterPassFormGrothtool />,
      },
      {
        path: 'citypass/:growthtool_code/:campaign_code',
        element: <RegisterPassFormCampaign />,
      },
      {
        path: 'tool/:growthtool_code/:campaign_code',
        element: <RegisterPassFormCampaign />,
      },
    ],
  },
  {
    path: 'integrations',
    children: [
      {
        path: 'ghl_location',
        element: <Associate />,
      },
      {
        path: 'crm/associate',
        element: <Associate />,
      },
    ],
  },
  {
    path: 'customer/pass/:pass_code',
    element: <Pass />,
  },
  {
    path: 'tool/:growthtool_code/user/:user_code',
    element: <PassGrowthTool />,
  },
  {
    path: 'public/newsfeed',
    element: <NewsFeed />,
  },
  {
    path: 'public/authorization',
    element: <Authorization />,
  },
]

export default PublicRoutes
