import { useAuth } from 'context/auth'
import { useLocationInfo } from 'context/location'
import React, { useState } from 'react'
import Api from 'utils/api'
import Modal from './modal'
import ClickOutSide from 'hooks/click-outside'
import LocationIcon from 'icon/locationicon'
import CloseIcon from 'icon/close'
import SecondaryBtn from './Buttons/secondary-btn'
import Button from './Buttons/login-button'
import InputFieldOutlined from './Inputs/dashboard-input'
import { Form, Formik } from 'formik'
import * as yup from 'yup'

function LocationListDropdown() {
  const [showMenu, setShowMenu] = useState(false)
  const [openCreateLocation, setOpenCreateLocation] = useState(false)
  const { setCurrentUser } = useAuth()
  const { locations, saveLocationKey, location, setLocations } =
    useLocationInfo()

  const handleSelectLocation = async (selected_location) => {
    const { api_key } = selected_location
    const location = await locations.find((loc) => loc.api_key === api_key)
    const { response } = await Api('/user', 'put', {
      payload: { selected_location: location.api_key },
      notify: true,
    })
    if (response) {
      saveLocationKey(location.api_key)
      setCurrentUser(response.data)
    }
  }

  const handleCreateLocation = async (form, options) => {
    console.log(form)
    const { response } = await Api('/location', 'post', {
      payload: {
        name: form.name,
      },
      notify: true,
    })
    setShowMenu(false)
    options.setSubmitting(false)
    if (response) {
      locations.push(response?.data)
      saveLocationKey(response?.data?.api_key)
      setLocations([...locations])
    }
  }

  return (
    <>
      <ClickOutSide onClickOutside={() => setShowMenu(false)}>
        <div class='relative inline-block text-left'>
          <div>
            <button
              onClick={() => setShowMenu(!showMenu)}
              type='button'
              class='inline-flex w-36 truncate justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
            >
              {location ? location.name : 'Select Business'}
              <svg
                class={`-mr-1 h-5 w-5 text-gray-400 transition-all ease-in transform duration-200 ${
                  showMenu ? 'rotate-180' : 'rotate-0'
                }`}
                viewBox='0 0 20 20'
                fill='currentColor'
                aria-hidden='true'
                data-slot='icon'
              >
                <path
                  fill-rule='evenodd'
                  d='M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z'
                  clip-rule='evenodd'
                />
              </svg>
            </button>
          </div>
          <div
            class={`transition duration-100  ${
              showMenu
                ? 'ease-out transform opacity-100 scale-100'
                : 'ease-in transform opacity-0 scale-95'
            } absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
            tabindex='-1'
          >
            {showMenu && (
              <div class='py-1'>
                {locations.map((loc) => (
                  <div
                    onClick={() => handleSelectLocation(loc)}
                    class={`block cursor-pointer px-4 py-2 text-sm hover:bg-gray-100 ${
                      loc._id == location._id
                        ? 'bg-gray-100 text-gray-900 outline-none'
                        : 'text-gray-700'
                    }`}
                  >
                    {loc.name}
                  </div>
                ))}
                <div
                  onClick={() => setOpenCreateLocation(true)}
                  class={`block cursor-pointer px-4 py-2 text-sm hover:text-blue-500 text-center text-blue-400 underline`}
                >
                  +Create
                </div>
              </div>
            )}
          </div>
        </div>
      </ClickOutSide>
      <Modal
        open={openCreateLocation}
        onClose={() => setOpenCreateLocation(false)}
      >
        <div className='flex items-center justify-between p-4 md:p-5 border-b rounded-t'>
          <h3 className='text-xl font-semibold text-gray-900 flex items-center'>
            <LocationIcon size={30} />{' '}
            <span className='ml-2'>{'Create New Business'}</span>
          </h3>
          <button
            onClick={() => setOpenCreateLocation(false)}
            type='button'
            className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center'
          >
            <CloseIcon />
            <span className='sr-only'>Close modal</span>
          </button>
        </div>
        <Formik
          initialValues={{ name: '' }}
          validationSchema={yup.object({
            name: yup.string().required().label('Name'),
          })}
          onSubmit={handleCreateLocation}
        >
          {({ isValid, isSubmitting }) => (
            <Form>
              <div className='w-96 bg-white'>
                <div className='p-4'>
                  <InputFieldOutlined label={'Name'} name={'name'} required />
                </div>

                <div className='flex justify-end items-center p-4 border-t border-gray-200 rounded-b '>
                  <SecondaryBtn
                    onClick={() => setOpenCreateLocation(false)}
                    label={'Cancel'}
                    className={'mr-3'}
                  />
                  <Button
                    processing={isSubmitting}
                    type={'submit'}
                    disabled={!isValid || isSubmitting}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default LocationListDropdown
